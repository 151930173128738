import { Box } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

enum ForecastChangeDirection {
  UP = "up",
  DOWN = "",
  EQUAL = 0,
}

interface ForecastCellProps {
  actualQuantity: string;
  modifiedQuantity: string;
  sale: string;
  isDc: boolean;
}

const config = {
  [ForecastChangeDirection.EQUAL]: {
    icon: <></>,
    color: "#727272",
    fontSize: "16px",
  },
  [ForecastChangeDirection.UP]: {
    icon: <ArrowDropUpIcon style={{ color: "#80c343" }} className="arrow-icon" />,
    color: "#80c343",
    fontSize: "12px",
  },
  [ForecastChangeDirection.DOWN]: {
    icon: <ArrowDropDownIcon style={{ color: "#EB2529" }} className="arrow-icon" />,
    color: "#EB2529",
    fontSize: "12px",
  },
};

const getChangeDirection = (actualQuantity: number, modifiedQuantity: string): ForecastChangeDirection => {
  const isEqual = Number(modifiedQuantity) === actualQuantity || modifiedQuantity == "";
  const isUp = Number(modifiedQuantity) > actualQuantity;

  if (isEqual) return ForecastChangeDirection.EQUAL;
  return isUp ? ForecastChangeDirection.UP : ForecastChangeDirection.DOWN;
};

const ForecastCell = (props: ForecastCellProps) => {
  const { actualQuantity, modifiedQuantity, sale, isDc } = props;

  const changeDirection = getChangeDirection(Number(actualQuantity), modifiedQuantity);
  const { icon, color, fontSize } = config[changeDirection];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box component="span" sx={{ color: color, fontSize: fontSize }}>
        {changeDirection === ForecastChangeDirection.EQUAL ? actualQuantity : <s>{actualQuantity}</s>}
      </Box>
      {changeDirection !== ForecastChangeDirection.EQUAL && (
        <div style={{ height: "18px", fontSize: "16px", color: "#0A0A0A" }}>
          {icon}
          <b>{modifiedQuantity}</b>
        </div>
      )}
      {!isDc && (
        <Box component="span" sx={{ color: "rgba(147, 147, 147, 0.62)", fontSize: "12px" }}>
          {sale}
        </Box>
      )}
    </Box>
  );
};

export default ForecastCell;
