import { DataGrid } from "@components";
import OrderContext from "@context/OrderContext";
import StoreContext from "@context/StoreContext";
import { ContentCopy, Delete, Edit, FindInPage } from "@mui/icons-material";
import {
  DataGridProProps,
  GridActionsCellItem,
  GridFilterModel,
  GridRowSelectionModel,
  getGridDateOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid-pro";
import GenericPage from "@templates/GenericPage";
import { statusRenderer } from "@utils/dataGrid";
import { dateFormat, dateTimeFormat } from "@utils/misc";
import { useAuth } from "react-oidc-context";
import moment from "moment";
import React, { FC, useContext, useMemo, useState, useEffect } from "react";
import {
  CopyOrderMutation,
  GetCurrentOrderByStoreDocument,
  GetordersByStoreQueryVariables,
  OrderDetail,
  OrderSummary,
  StatusType,
  StoreOrderRoster,
  useCopyOrderMutation,
  useDeleteOrderMutation,
  useGetRosterLazyQuery,
  useGetordersByStoreLazyQuery,
} from "../../graphql/types";
import OrderRostersModal from "./OrderRostersModal";
import { useNavigate } from "react-router-dom";

const DEFAULT_PAGE_SIZE = 10;

const OrdersListPage: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [openRostersModal, setOpenRostersModal] = useState(false);
  const [rosterId, setRosterId] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [storeOrders, setStoreOrders] = useState<OrderSummary[]>([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const { storeId } = useContext(StoreContext);
  const { order: currentOrder, setOrder } = useContext(OrderContext);
  const [filterData, setFilterData] = useState({});
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  const [copyOrderMutation, { loading: copyInProgress }] = useCopyOrderMutation();
  const [deleteOrderMutation] = useDeleteOrderMutation({
    update(cache, { data }) {
      if (data?.deleteOrder?.hasErrors) {
        return;
      }

      setOrder({} as OrderDetail);
      cache.evict({ fieldName: "currentOrderByStore" });
      cache.evict({ fieldName: "ordersByStore" });
    },
  });

  const [getOrders, { loading: ordersLoading, networkStatus, fetchMore }] = useGetordersByStoreLazyQuery();

  useEffect(() => {
    if (!storeId) return;
    getOrders({
      variables: {
        storeId: storeId || "",
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      } as GetordersByStoreQueryVariables,
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const orders = data?.ordersByStore?.items || [];
        const pendingOrders = orders.filter(order => order?.status === StatusType.InProgress || order?.status === StatusType.PendingSubmission);
        setPendingOrdersCount(pendingOrders.length);
        setStoreOrders(orders as OrderSummary[]);
        setTotalOrdersCount(data?.ordersByStore?.totalCount || 0);
      },
    });
  }, [storeId]);

  const [rosters, setRosters] = useState<StoreOrderRoster[]>([]);
  const [getRosters] = useGetRosterLazyQuery();
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

  const viewHandler = (order: OrderDetail) => {
    navigate(`/order/view/${order.id}/${order.status.toLowerCase()}`);
  };

  const resumeHandler = () => {
    if (!currentOrder.id) return;
    navigate(`/order/edit/${currentOrder.id}/`);
  };

  useEffect(() => {
    if (!storeId) return;
    getRosters({
      variables: {
        offset: 0,
        limit: 50,
        siteNumber: storeId || "",
      },
      onCompleted: data => {
        const rosters = data.storeOrderRostersBySite?.items || [];
        setRosters(rosters as StoreOrderRoster[]);
      },
    });
  }, [storeId]);

  const handleCellClick: DataGridProProps["onCellClick"] = params => {
    if (params.field === "actions" || params.field === "__check__") return;
    const { status, id } = params.row;

    switch (status) {
      case StatusType.InProgress:
        navigate(`/order/edit/${id}/`);
        break;
      default:
        navigate(`/order/view/${id}/${status.toLowerCase()}`);
        break;
    }
  };

  const getFilter = (columnField: string, value: string) => {
    switch (columnField) {
      case "deliveryDate": {
        const dateUtc = moment(value).utc().format();
        return { deliveryDateUtc: dateUtc };
      }
      case "submittedDateTime": {
        const dateUtc = moment(value).utc().format();
        return { submittedDateUtc: dateUtc };
      }
      case "status": {
        return { orderStatus: value };
      }
      default: {
        return {};
      }
    }
  };

  const onFilterChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      const { field, value } = filterModel.items[0] || {};

      const variables = {
        storeId: storeId || "",
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      } as GetordersByStoreQueryVariables;

      let filters = {};
      if (value && field) {
        filters = getFilter(field, value);
        setFilterData(filters);
      }

      getOrders({
        variables: {
          ...variables,
          ...filters,
        },
        fetchPolicy: "cache-and-network",
        onCompleted: data => {
          const orders = data?.ordersByStore?.items || [];
          setStoreOrders(orders as OrderSummary[]);
          setTotalOrdersCount(data?.ordersByStore?.totalCount || 0);
        },
      });
    },
    [storeId]
  );

  const columns: DataGridProProps["columns"] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Purchase Order Id",
        width: 130,
        valueGetter: params => (params.row.status === "PendingSubmission" || params.row.status === "InProgress" ? "#####" : params.row.id),
        filterable: false,
      },
      {
        field: "status",
        headerName: "Status",
        width: 170,
        type: "singleSelect",
        valueOptions: ["InProgress", "Submitted", "Closed", "PendingSubmission"],
        valueGetter: params => (params.row.status === "PendingSubmission" ? "Submitted" : params.row.status),
        renderCell: statusRenderer,
        filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === "is"),
      },
      {
        field: "deliveryDate",
        headerName: "Delivery Date",
        type: "string",
        width: 170,
        valueGetter: params => {
          return moment(params.row.deliveryDate).format(dateFormat);
        },
        filterOperators: getGridDateOperators().filter(operator => operator.value === "is"),
      },
      {
        field: "submittedDateTime",
        headerName: "Created Date",
        type: "string",
        width: 170,
        valueGetter: ({ row }) => {
          return row.submittedDateTime ? moment(row.submittedDateTime).format(dateTimeFormat) : "——/——/——";
        },
        filterOperators: getGridDateOperators().filter(operator => operator.value === "is"),
      },

      {
        field: "sourceOfSupply",
        headerName: "Supplier",
        width: 220,
        valueGetter: params => (params.row.sourceOfSupplyName ? params.row.sourceOfSupplyName : "Not available"),
        filterable: false,
      },
      {
        field: "articleCount",
        headerName: "Lines",
        type: "number",
        width: 100,
        filterable: false,
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        align: "right",
        flex: 1,
        filterable: false,
        getActions: params => {
          const actions = [];
          const isPendingOrder = params.row.status === "InProgress";
          const { id } = params.row;
          isPendingOrder &&
            actions.push(
              <GridActionsCellItem
                icon={<Edit />}
                label="Resume Order"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  resumeHandler();
                  e.stopPropagation();
                }}
                onResize={() => {}}
                onResizeCapture={() => {}}
              />,
              <GridActionsCellItem
                label="Delete"
                icon={<Delete color="error" />}
                onClick={() => {
                  deleteOrderMutation({
                    variables: {
                      orderId: String(currentOrder.id),
                    },
                  });
                }}
                key="delete"
                onResize={() => {}}
                onResizeCapture={() => {}}
              />
            );

          if (!isPendingOrder) {
            !currentOrder.id
              ? actions.push(
                  <GridActionsCellItem
                    icon={<ContentCopy />}
                    label="Copy Order"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setOpenRostersModal(true);
                      setOrderId(id);
                    }}
                    onResize={() => {}}
                    onResizeCapture={() => {}}
                  />,
                  <GridActionsCellItem
                    icon={<FindInPage />}
                    label="View Order"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      viewHandler(params.row as OrderDetail);

                      e.stopPropagation();
                    }}
                    showInMenu
                    onResize={() => {}}
                    onResizeCapture={() => {}}
                  />
                )
              : actions.push(
                  <GridActionsCellItem
                    icon={<FindInPage />}
                    label="View Order"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      viewHandler(params.row as OrderDetail);

                      e.stopPropagation();
                    }}
                    showInMenu
                    onResize={() => {}}
                    onResizeCapture={() => {}}
                  />
                );
          }
          return actions;
        },
      },
    ],
    [currentOrder]
  );

  return (
    <GenericPage maxWidth="xl" title="Orders">
      <OrderRostersModal
        open={openRostersModal}
        onClose={() => setOpenRostersModal(false)}
        onRosterSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRosterId(Number(event.target.value));
        }}
        rosters={rosters}
        rosterId={rosterId}
        onSubmit={() => {
          setOpenRostersModal(false);
          const roster = rosters.find(roster => roster.id === rosterId);
          const order = storeOrders.find(order => String(order.id) === String(orderId));
          copyOrderMutation({
            variables: {
              orderInput: {
                orderId: String(orderId),
                isPendingOrder: order?.status === StatusType.PendingSubmission,
                rosterId,
                sourceOfSupplyId: roster?.sourceOfSupplyNumber || "",
                sourceOfSupplyName: roster?.sourceOfSupplyName || "",
                deliveryDateTimeUtc: roster?.deliveryDateTimeUtc || "",
                rosterCutOffDateTimeUtc: roster?.cutOffDateTimeUtc || "",
                storeId: storeId || "",
                createdBy: auth.user?.profile.sub || "",
              },
            },
            refetchQueries: [{ query: GetCurrentOrderByStoreDocument, variables: { storeId } }],
            awaitRefetchQueries: true,
            onCompleted: (data: CopyOrderMutation) => {
              setOpenRostersModal(false);

              if (data?.copyOrder?.hasErrors) {
                return;
              }
              const newOrder = data?.copyOrder?.item;
              const orderId = newOrder?.id;
              const failedArticles = newOrder?.failedArticles;
              navigate(`/order/edit/${orderId}/`, {
                state: { failedArticles },
              });
            },
          });
        }}
      />
      <DataGrid
        onRowSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        slotProps={{ toolbar: { showQuickFilter: false } }}
        checkboxSelection={false}
        rowSelectionModel={selectionModel}
        getRowId={row => `${row.id}${Math.random() * 100}`}
        hideFooter={false}
        onCellClick={handleCellClick}
        columns={columns}
        loading={ordersLoading || networkStatus === 3 || copyInProgress}
        rows={storeOrders || []}
        pagination
        rowCount={totalOrdersCount}
        onFilterModelChange={onFilterChange}
        filterMode="server"
        disableMultipleColumnsFiltering
        onPaginationChange={paginationModel => {
          const { page, pageSize } = paginationModel;
          const offset = page * (pageSize - pendingOrdersCount);

          fetchMore({
            variables: {
              storeId: storeId || "",
              offset: offset <= 0 && page > 0 ? 1 : offset,
              limit: pageSize,
              ...filterData,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => (!fetchMoreResult ? prevResult : fetchMoreResult),
          });
        }}
        initialPaginationModel={{ page: 0, pageSize: DEFAULT_PAGE_SIZE }}
      />
    </GenericPage>
  );
};

export default OrdersListPage;
