import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { toCurrency } from "@utils/locale";
import React from "react";

interface Props {
  totalItems: number;
  totalUniqueItems: number;
  totalCost: number;
  totalRrp: number;
  totalGp: number;
}

const DataGridOrderSummary = ({ totalItems = 0, totalUniqueItems = 0, totalCost = 0, totalRrp = 0, totalGp = 0 }: Props) => (
  <Box px={2} py={2} bgcolor={theme => theme.palette.grey[300]} borderTop={theme => `1px solid ${theme.palette.grey[400]}`}>
    <Stack direction="row" spacing={2}>
      <Typography>
        <strong>{`Total Cost: ${toCurrency(totalCost)}`}</strong>
      </Typography>
      <Typography variant="body2">{`RRP: $${Number(totalRrp).toLocaleString()}`}</Typography>
      <Typography variant="body2">{`GP%: ${totalGp}`}</Typography>
      <Typography variant="body2">{`Cartons: ${Number(totalItems).toLocaleString()}`}</Typography>
      <Typography variant="body2">{`Lines: ${Number(totalUniqueItems).toLocaleString()}`}</Typography>
    </Stack>
  </Box>
);

export default DataGridOrderSummary;
