import React, { FC, ReactNode } from "react";
import { Box, Modal as MuiModal } from "@mui/material";

interface Props {
  ariaLabelBy?: string;
  ariaDescribedBy?: string;
  onClose: () => void;
  open: boolean;
  children: ReactNode;
}

const Modal: FC<Props> = props => {
  const { ariaLabelBy = "modal-title", ariaDescribedBy = "modal-description", children, onClose, open } = props;
  return (
    <MuiModal open={open} onClose={onClose} aria-labelledby={ariaLabelBy} aria-describedby={ariaDescribedBy}>
      <Box
        sx={{
          position: "absolute" as const,
          display: "flex",
          flexDirection: "column",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: 300,
          maxWidth: 400,
          bgcolor: "background.paper",
          border: "1px solid #ccc",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        {children}
      </Box>
    </MuiModal>
  );
};

export default Modal;
