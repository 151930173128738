import { MonetizationOn, NewReleases } from "@mui/icons-material";
import { Badge, BadgeProps, Box, Stack, Tooltip, useTheme } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import React from "react";
import { Article, OrderDetail, StatusType } from "../graphql/types";

const statusRenderer = (params: GridRenderCellParams) => {
  let color: BadgeProps["color"];

  switch (params.value as OrderDetail["status"]) {
    case StatusType.InProgress:
      color = "success";
      break;
    case StatusType.Closed:
    case StatusType.Submitted:
      color = "warning";
      break;
    default:
      color = "error";

      break;
  }

  return (
    <span>
      <Badge
        color={color}
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      ></Badge>
      <span style={{ paddingLeft: "1rem" }}>{params.value}</span>
    </span>
  );
};

const deliveryRenderer = (params: GridRenderCellParams) => {
  let color: BadgeProps["color"];

  switch (params.row.status as OrderDetail["status"]) {
    case StatusType.InProgress:
      color = "success";
      break;
    case StatusType.Closed:
    case StatusType.Submitted:
      color = "warning";
      break;
    default:
      color = "error";

      break;
  }

  return (
    <span>
      <Badge
        color={color}
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      ></Badge>
      <span style={{ paddingLeft: "1rem" }}>{moment(params.value).format("DD/MM/YY HH:MM A")}</span>
    </span>
  );
};

const currencyRenderer = (params: GridRenderCellParams) => {
  const value: number = params.value && !Number.isNaN(params.value) ? (params.value as number) : 0;

  return (
    <span>
      {new Intl.NumberFormat("en-NZ", {
        style: "currency",
        currency: "NZD",
      }).format(value)}
    </span>
  );
};

const tooltipRenderer = (params: GridRenderCellParams) => {
  const value = params.value as string;
  if (!value) return "";

  return (
    <Tooltip title={value} arrow>
      <span
        style={{
          textOverflow: "ellipsis",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >{`${value}`}</span>
    </Tooltip>
  );
};

const badgeRenderer = ({ row }: GridRenderCellParams<Article>) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={-1.25} sx={{ filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, .2))" }}>
      {row.temporarilyOutOfStock && (
        <Tooltip title={"Temporarily out of stock"} arrow>
          <Box
            sx={{
              background: theme.palette.primary.main,
              color: "#FFF",
              padding: "0 8px",
              fontSize: "12px",
              borderRadius: "5px",
              fontWeight: "bold",
              lineHeight: "1.5",
            }}
          >
            TOS
          </Box>
        </Tooltip>
      )}
      {row.endOfSeason && (
        <Tooltip title={"End of season"} arrow>
          <Box
            sx={{
              background: theme.palette.primary.main,
              color: "#FFF",
              padding: "0 8px",
              fontSize: "12px",
              borderRadius: "5px",
              fontWeight: "bold",
              lineHeight: "1.5",
            }}
          >
            EOS
          </Box>
        </Tooltip>
      )}
      {/* {row.onPromo && (
        <Tooltip title={"Item is on promotion"} arrow>
          <MonetizationOn fontSize="small" color="success" />
        </Tooltip>
      )} */}
      {row.newArticle && (
        <Tooltip title={"New item"} arrow>
          <NewReleases fontSize="small" color="primary" />
        </Tooltip>
      )}
    </Stack>
  );
};

export { deliveryRenderer, statusRenderer, currencyRenderer, tooltipRenderer, badgeRenderer };
