import { useParams } from "react-router-dom";
import { transformLinesToCartItems } from "@utils/orders";
import React, { FC, useContext, useEffect, useState } from "react";
import { OrderDetail, StatusType, useGetOrderByOrderIdLazyQuery } from "../../graphql/types";
import OrderDetails from "./OrderDetails";
import StoreContext from "@context/StoreContext";

const OrderView: FC = () => {
  const { orderId, orderStatus } = useParams();
  const [order, setOrder] = useState<OrderDetail>({} as OrderDetail);
  const [getOrderByOrderId, { loading }] = useGetOrderByOrderIdLazyQuery();
  const { storeId } = useContext(StoreContext);

  useEffect(() => {
    if (orderId) {
      getOrderByOrderId({
        variables: {
          orderId,
          storeId: storeId || "",
          isPendingOrder: orderStatus === StatusType.PendingSubmission.toLowerCase(),
        },
        onCompleted: data => {
          const order = data?.retrieveOrder?.item;
          if (order) {
            setOrder(order as OrderDetail);
          }
        },
      });
    }
  }, [orderId, orderStatus]);

  return (
    <OrderDetails
      order={order}
      items={transformLinesToCartItems(order.articles)}
      loading={loading}
      totalUniqueItems={order.numberOfCartons}
      totalItems={order.articleCount}
      totalCost={order.totalCost}
      totalGp={order.grossProfitPercentage}
      totalRrp={`${order.recommendedRetailPrice}`}
      rosterId={order.rosterId}
      isPendingSubmissionOrder={orderStatus === StatusType.PendingSubmission.toLowerCase()}
      isReadonly={true}
    ></OrderDetails>
  );
};

export default OrderView;
