import React, { FC, ReactNode, useContext, useEffect } from "react";
import { Avatar, Box, CircularProgress, Container, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import LayoutContext from "@context/LayoutContext";
import { LayoutDefaultProps } from "@layouts/index";

export type GenericListPageProps = LayoutDefaultProps & {
  items: {
    primary: string;
    secondary?: string;
    to: string;
  }[];
  loading: boolean;
  title: string;
  actions: ReactNode;
};

const GenericListPage: FC<GenericListPageProps> = ({ items, title, actions, breadcrumbs, loading = false }) => {
  const navigate = useNavigate();
  const { setLayout } = useContext(LayoutContext);

  useEffect(() => {
    setLayout({
      title,
      actions,
      breadcrumbs,
    });
  }, [title, actions, breadcrumbs]);

  return (
    <Container
      maxWidth="md"
      sx={{
        height: {
          xs: "calc(100% - 2rem)",
          md: "100%",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Box sx={{ py: 2 }}>
        <Paper elevation={2}>
          {loading ? (
            <Box sx={{ textAlign: "center" }} py={2}>
              <CircularProgress />
            </Box>
          ) : (
            <List dense sx={{ width: "100%" }}>
              {items.length === 0 ? (
                <Box sx={{ textAlign: "center" }} py={2}>
                  <p> No items available</p>
                </Box>
              ) : (
                items.map(({ primary, secondary, to }, index) => {
                  return (
                    <ListItem button onClick={() => navigate(to)} key={index}>
                      <ListItemAvatar>
                        <Avatar variant="rounded">{primary.charAt(0).toUpperCase()}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={primary} secondary={secondary || null} />
                      <IconButton edge="end" aria-label="comments">
                        <BsArrowRight />
                      </IconButton>
                    </ListItem>
                  );
                })
              )}
            </List>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default GenericListPage;
