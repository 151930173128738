import React, { FC, useContext } from "react";
import WithCaution from "@components/WithCaution";
import { CopyAll, MoreVert, Print, Send } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { toCurrency } from "@utils/locale";
import moment from "moment";
import { GetReportQuery, OrderDetail, ReportArticlesSelectionType, ReportType, StatusType, StoreOrderRoster, useGetReportLazyQuery } from "../../graphql/types";
import { Item } from "react-use-cart";
import StoreContext from "@context/StoreContext";
import { openPrintWindow } from "@utils/misc";
import Order from "../../enums/order";

interface Props {
  readonly: boolean;
  hasOpenOrder: boolean;
  onCopyOrder: () => void;
  onSubmitOrder: () => void;
  onRosterNotValid: () => void;
  totalCost: number;
  totalRrp: string;
  rosters: StoreOrderRoster[];
  items: Item[];
  order: OrderDetail;
}

const OrderDetailsActions: FC<Props> = props => {
  const { readonly, hasOpenOrder, onCopyOrder, onSubmitOrder, totalCost, totalRrp, rosters, items, order, onRosterNotValid } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [printWindow, setPrintWindow] = React.useState<null | Window>(null);
  const open = Boolean(anchorEl);
  const { selectedStore } = useContext(StoreContext);
  const [getReport] = useGetReportLazyQuery();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = () => {
    getReport({
      variables: {
        reportParams: {
          reportType: ReportType.Daily,
          articlesType: ReportArticlesSelectionType.LatestOrder,
          storeId: selectedStore?.siteNumber || "",
          categoryIds: [],
          storeName: selectedStore?.siteName || "",
          orderId: `${order.id || ""}`,
          isPendingOrder: order.status === StatusType.PendingSubmission || order.status === StatusType.InProgress,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data: GetReportQuery) => {
        if (printWindow) {
          printWindow.close();
        }
        const fileContent = data.report?.item?.fileContent;
        const printWin = openPrintWindow(fileContent || "");
        setPrintWindow(printWin);
        handleClose();
      },
    });
  };
  return (
    <>
      <Stack direction="row" alignItems="center">
        {matches && (
          <>
            <Typography variant="h6" component="div" sx={{ mb: 0, mr: 2 }}>{`Total Cost: ${toCurrency(totalCost || 0)}`}</Typography>
            <Typography variant="h6" component="div" sx={{ mb: 0, mr: 2 }}>{`RRP: ${toCurrency(Number(totalRrp) || 0)}`}</Typography>
          </>
        )}

        {readonly && !hasOpenOrder && (
          <IconButton color="inherit" onClick={onCopyOrder}>
            <CopyAll />
          </IconButton>
        )}
        {!readonly && (
          <WithCaution title="Send Order?" severity="info" onProceed={onSubmitOrder} onError={onRosterNotValid} autoClose={false}>
            {({ handleOpen, handleError }) => (
              <IconButton
                color="inherit"
                onClick={() => {
                  const currentRoster = rosters?.find(roster => roster.id === order.rosterId);
                  const cutOffDateTimeUtc = currentRoster?.cutOffDateTimeUtc;
                  if (cutOffDateTimeUtc) {
                    const isRosterValid = moment().isBefore(cutOffDateTimeUtc);
                    if (!isRosterValid) {
                      handleError();
                      return;
                    }
                  } else {
                    handleError();
                    return;
                  }
                  handleOpen();
                }}
                disabled={!items.length || order.numberOfCartons < Order.MINIMUM_CARTON_NUMBER}
              >
                <Send />
              </IconButton>
            )}
          </WithCaution>
        )}

        <IconButton color="inherit" edge="end" onClick={handleOpen}>
          <MoreVert />
        </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handlePrint} sx={{ minWidth: 240 }}>
          <ListItemIcon>
            <Print fontSize="small" />
          </ListItemIcon>
          <ListItemText>Print</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default OrderDetailsActions;
