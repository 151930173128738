import { Box, TextField } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { ForecastQuantity, StoreForecast } from "src/graphql/types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Props {
  params: GridRenderCellParams;
  storeForecasts: StoreForecast[];
  forecastTo: ForecastQuantity;
  isDc: boolean;
}

const EditForecastCell = (props: Props) => {
  const { params, storeForecasts, forecastTo, isDc } = props;
  if (forecastTo.enteredQty === forecastTo.quantity || forecastTo.enteredQty == "") {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box component="span" sx={{ color: "#727272", fontSize: "12px" }}>
          {forecastTo?.quantity || 0}
        </Box>
        <TextField
          type="number"
          id="standard-basic"
          defaultValue={forecastTo.enteredQty || forecastTo.quantity || 0}
          inputProps={{
            className: "customText",
          }}
        />
        {!isDc && (
          <Box component="span" sx={{ color: "rgba(147, 147, 147, 0.62)", fontSize: "12px" }}>
            {forecastTo?.sale || 0}
          </Box>
        )}
      </Box>
    );
  } else if (Number(forecastTo.enteredQty) > Number(forecastTo.quantity)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <s className="color-green">{forecastTo?.quantity}</s>
        <Box sx={{ height: "18px", fontSize: "12px", color: "#0A0A0A" }}>
          <ArrowDropUpIcon className="arrow-icon color-green" />
          <TextField
            type="number"
            id="standard-basic"
            defaultValue={forecastTo.enteredQty || forecastTo.quantity}
            inputProps={{
              className: "customText",
            }}
          />
        </Box>
        {!isDc && (
          <Box component="span" sx={{ color: "rgba(147, 147, 147, 0.62)", fontSize: "12px" }}>
            {forecastTo?.sale}
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <s style={{ color: "#EB2529" }}>{forecastTo?.quantity}</s>
        <Box style={{ height: "18px", fontSize: "12px", color: "#0A0A0A" }}>
          <ArrowDropDownIcon style={{ color: "#EB2529" }} className="arrow-icon" />
          <TextField
            type="number"
            id="standard-basic"
            defaultValue={forecastTo.enteredQty || forecastTo.quantity}
            inputProps={{
              className: "customText",
            }}
          />
        </Box>
        {!isDc && (
          <Box component="span" sx={{ color: "rgba(147, 147, 147, 0.62)", fontSize: "12px" }}>
            {forecastTo?.sale}
          </Box>
        )}
      </Box>
    );
  }
};

export default EditForecastCell;
