import { Box, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";

interface Props {
  currentDate: Date;
  weekDay: string;
  month: string;
}

const ForecastCellHeader = (props: Props) => {
  const theme = useTheme();
  const { currentDate, weekDay, month } = props;
  return (
    <Box sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
      <div style={{ height: "20px", fontSize: "12px" }}>{weekDay}</div>
      <span style={{ fontSize: "16px" }}>
        {moment(currentDate).format("DD")}/{month}
      </span>
    </Box>
  );
};

export default ForecastCellHeader;
