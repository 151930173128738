import React, { FC, ReactNode, useContext, useEffect } from "react";
import { Container } from "@mui/material";
import { LayoutDefaultProps } from "@layouts/index";
import LayoutContext from "@context/LayoutContext";

export type GenericPageProps = LayoutDefaultProps & {
  maxWidth?: "sm" | "md" | "lg" | "xl" | false;
  fab?: ReactNode;
  children: ReactNode;
  title: string;
};

const GenericPage: FC<GenericPageProps> = ({ children, maxWidth = "md", fab, breadcrumbs, title, actions }) => {
  const { setLayout } = useContext(LayoutContext);

  useEffect(() => {
    setLayout({
      title,
      actions,
      breadcrumbs,
    });
  }, [title, breadcrumbs, actions]);

  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{
          height: {
            xs: `calc(100% - ${breadcrumbs ? `5rem` : `3rem`})`,
            md: `calc(100% - ${breadcrumbs ? `3rem` : `0rem`})`,
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          py: 2,
        }}
      >
        {children}
      </Container>
      {fab}
    </>
  );
};

export default GenericPage;
