import { default as React, FC, useContext, useEffect, useState, useMemo, ChangeEvent } from "react";
import { Box } from "@mui/material";
import GenericPage from "@templates/GenericPage";
import { DataGrid } from "@components";
import { GridColumnGroupingModel } from "@mui/x-data-grid";
import { CalculatedDates, DcForecast, UpdateDcForecastInput, useGetDcForecastQuery, useUpdateDcForecastMutation } from "../../graphql/types";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridToolbar, MuiEvent } from "@mui/x-data-grid-pro";
import StoreContext from "@context/StoreContext";
import SupplierContext from "@context/SupplierContext";
import moment from "moment";
import { addDaysToDate } from "@utils/misc";
import getGridGroups from "./getGridGroups";
import getGridColumns from "./getGridColumns";
import CustomToolbar from "./CustomToolBar";

const forecastDatesInitialState = ((): CalculatedDates => {
  const monday = moment().startOf("isoWeek").add(1, "week");

  const forecastStartDate = moment(monday).add(7, "days").toDate();
  const forecastEndDate = addDaysToDate(forecastStartDate, 13);

  //sales date
  const saleStartDate = moment(monday).add(-21, "days").toDate();
  const saleEndtDate = addDaysToDate(saleStartDate, 13);

  return {
    startDate: forecastStartDate,
    endDate: forecastEndDate,
    preEnabled: true,
    nextEnabled: false,
    saleEndDate: saleEndtDate,
    saleStartDate: saleStartDate,
  };
})();

const DcForecastPage: FC = () => {
  const { storeId, stores: userStores, } = useContext(StoreContext);
  const [storeForecasts, setStoreForecasts] = useState<DcForecast[]>([]);
  const [calcDates, setCalcDates] = useState<CalculatedDates>(forecastDatesInitialState);
  const { selectedSourceOfSupplier } = useContext(SupplierContext);
  const [getAllForecast, { loading: allArticleLoading }] = useGetDcForecastQuery();
  const [updateDcForecastMutation] = useUpdateDcForecastMutation();
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(true);
  const sourceOfSupplyNumber = selectedSourceOfSupplier?.sourceOfSupplyNumber || "9733";
  const isAdmin = userStores.find(userStore => userStore?.siteName?.toLowerCase() === "wdl");

  useEffect(() => {
    if (!storeId) return;
    getAllForecast({
      variables: {
        from: calcDates?.startDate.toDateString(),
        to: calcDates?.endDate.toDateString(),
        dc: sourceOfSupplyNumber,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        const forecast = data?.dcForecast?.items || [];
        setStoreForecasts(forecast as DcForecast[]);
      },
      onError: () => {
        setStoreForecasts([]);
      },
    });
  }, [storeId, setStoreForecasts, calcDates, sourceOfSupplyNumber]);

  const columns: GridColDef[] = useMemo(() => {
    return getGridColumns(storeForecasts, calcDates, true);
  }, [calcDates.startDate, storeForecasts]);

  const columnGroups: GridColumnGroupingModel = useMemo(() => {
    return getGridGroups(calcDates, true);
  }, [calcDates]);

  function HandleClick(type: string, action: boolean) {
    if (!action) return;

    const selectedStartDate = calcDates.startDate;
    const selectedEndDate = calcDates.endDate;

    const fortnightDays = 14;
    const previousFortnight = {
      startDate: addDaysToDate(selectedStartDate, -fortnightDays),
      endDate: addDaysToDate(selectedStartDate, -1),
    };
    const nextFortnight = {
      startDate: addDaysToDate(selectedEndDate, 1),
      endDate: addDaysToDate(selectedEndDate, fortnightDays),
    };

    const forecastStartDate = type === "previous" ? previousFortnight.startDate : nextFortnight.startDate;
    const forecastEndDate = type === "previous" ? previousFortnight.endDate : nextFortnight.endDate;

    const saleStartDate = addDaysToDate(forecastStartDate, -2 * fortnightDays);
    const saleEndDate = addDaysToDate(forecastStartDate, -fortnightDays);

    setCalcDates({
      startDate: forecastStartDate,
      endDate: forecastEndDate,
      preEnabled: true,
      nextEnabled: forecastStartDate < moment().toDate(),
      saleEndDate: saleEndDate,
      saleStartDate: saleStartDate,
    });
  }

  const clonedStoredForecasts: DcForecast[] = useMemo(() => {
    return storeForecasts.map(storeForecast => ({
      ...storeForecast,
      forecast: storeForecast.forecast.map(fc => ({ ...fc })),
    }));
  }, [storeForecasts]);

  function handleOnChange(dayIndex: number, storeForecastIndex: string | undefined, enteredQuantity: string, weekStartDate: Date) {
    if (isNaN(Number(enteredQuantity)) || enteredQuantity === "") {
      return;
    }

    const selectedStoreForecastIndex = clonedStoredForecasts.findIndex(forecast => forecast.id === storeForecastIndex);
    const selectedStoreForecast = clonedStoredForecasts[selectedStoreForecastIndex];
    const selectedDayForecast = selectedStoreForecast.forecast[dayIndex];

    clonedStoredForecasts[selectedStoreForecastIndex].forecast[dayIndex].enteredQty = enteredQuantity;
    setStoreForecasts(clonedStoredForecasts);

    if (addDaysToDate(weekStartDate, 6) < addDaysToDate(selectedDayForecast?.date, 0)) {
      weekStartDate = addDaysToDate(weekStartDate, 7);
    }

    const updateForecastPayload: UpdateDcForecastInput = {
      dc: sourceOfSupplyNumber || "",
      articleNumber: selectedStoreForecast?.articleNumber || "",
      forecastDate: selectedDayForecast?.date || "",
      forecastEnteredQty: enteredQuantity,
      financialWeekStartDate: moment(weekStartDate).toISOString() || "",
    };

    updateForecast(updateForecastPayload);
  }

  function updateForecast(forecast: UpdateDcForecastInput) {
    if (forecast != undefined) {
      setUpdating(true);
      setUpdateSuccess(true);
      updateDcForecastMutation({ variables: { forecast } })
        .then(result => {
          if (result.data?.updateDcForecast.hasErrors) {
            setUpdateSuccess(false);
          }
          setUpdating(false);
        })
        .catch(() => {
          setUpdateSuccess(false);
          setUpdating(false);
        });
    }
  }

  return (
    <GenericPage title="Aggregated Store Forecast" maxWidth={false} actions={<></>}>
      {!isAdmin ? <Box>You dont have access to Aggregated Store Forecast</Box> : <Box
        sx={{
          height: "600px",
          ".MuiDataGrid-cell--editing": {
            "& ..MuiDataGrid-cell:focus": {
              border: "none",
            },
          },
          "& .MuiDataGrid-row--dynamicHeight": {
            backgroundColor: "#F5F5F5",
          },
          ".groupHeader": {
            borderWidth: "1px 0px 0px 1px",
            borderStyle: "solid",
            borderColor: "#e0e0e0",
            fontSize: "13px",
            backgroundColor: "#ECECEC",
            "&__forecast": {
              backgroundColor: "rgba(0, 174, 239, 0.06)",
            },
          },
          ".groupHeaderChild": {
            borderWidth: "0px 1px 0px 0px",
            borderStyle: "solid",
            borderColor: "#e0e0e0",
            fontSize: "12px",
            backgroundColor: "#ECECEC",
            "&__forecast": {
              backgroundColor: "rgba(0, 174, 239, 0.06)",
            },
          },
          ".customText": {
            padding: "0px !important",
            textAlign: "right",
          },
          ".MuiDataGrid-virtualScrollerRenderZone": {
            fontSize: "12px",
          },
          ".salesDate": {
            textAlign: "center",
            fontSize: "12px",
            color: "grey",
          },
          ".icon-styles": {
            verticalAlign: "middle",
            fontStyle: "bold",
            fontSize: "20px",
          },
          ".arrow-icon": {
            verticalAlign: "middle !important",
            marginLeft: "-24px !important",
            height: "18px",
          },
          ".color-green": {
            color: "#80c343",
          },
          "& .groupHeader > .MuiDataGrid-columnSeparator, & .groupHeaderChild > .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiPaper-root": {
            minHeight: "500px",
            height: "88vh",
          },
        }}
      >
        <DataGrid
          initialState={{ pinnedColumns: { left: ["description"] } }}
          density="standard"
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: () => <CustomToolbar calcDates={calcDates} HandleClick={HandleClick} updateSuccess={updateSuccess} updating={updating} />,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          autoPageSize
          autoHeight={false}
          loading={allArticleLoading}
          experimentalFeatures={{ columnGrouping: true }}
          rows={storeForecasts}
          columns={columns}
          checkboxSelection={false}
          columnGroupingModel={columnGroups}
          disableColumnResize
          disableColumnMenu
          disableMultipleColumnsSorting
          disableColumnReorder
          showColumnVerticalBorder
          showCellVerticalBorder
          sx={{ backgroundColor: "#F5F5F5" }}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              params.cellMode = "view";
            } else {
              const column = params.colDef;
              const fieldText = column.field;
              const forecastDayIndex = Number(fieldText?.split("-")[1]);
              const storeForecastIndex = params.row.id;
              const enteredQty = (event as ChangeEvent<HTMLInputElement>).target?.value;
              const weekStartDate = params.row.financialWeekStartDate;
              if (!enteredQty || Number(enteredQty) < 0) return;
              handleOnChange(forecastDayIndex, storeForecastIndex, enteredQty, weekStartDate);
            }
          }}
        />
      </Box>}
    </GenericPage>
  );
};

export default DcForecastPage;
