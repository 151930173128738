import WithCaution from "@components/WithCaution";
import { Delete } from "@mui/icons-material";
import { Box, darken, Fab, SpeedDial, SpeedDialAction, SpeedDialIcon, SxProps, Theme, Zoom } from "@mui/material";
import React, { FC } from "react";
import { GiFruitBowl } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

interface Props {
  readonly: boolean;
  selectionModel: GridRowSelectionModel;
  onDeleteArticles: () => void;
}

const fabStyle: SxProps = {
  position: "absolute",
  bottom: {
    xs: "6.5rem",
    md: "2rem",
  },
  right: {
    xs: "2rem",
    md: "2.5rem",
  },
};

const OrderDetailsFab: FC<Props> = props => {
  const navigate = useNavigate();
  const { selectionModel, onDeleteArticles, readonly } = props;
  if (readonly) return <></>;

  return (
    <>
      <Zoom
        in={selectionModel.length > 0}
        timeout={{
          enter: 200,
          exit: 200,
        }}
        style={{
          transitionDelay: `${selectionModel.length > 0 ? 200 : 0}ms`,
        }}
        unmountOnExit
      >
        <Box sx={fabStyle}>
          <WithCaution onProceed={onDeleteArticles}>
            {({ handleOpen }) => (
              <Fab
                type="button"
                color="secondary"
                sx={{
                  color: "error.contrastText",
                  bgcolor: "error.main",
                  "&:hover": {
                    bgcolor: (theme: Theme) => darken(theme.palette.error.main, 0.1),
                  },
                }}
                onClick={handleOpen}
              >
                <Delete />
              </Fab>
            )}
          </WithCaution>
        </Box>
      </Zoom>
      {selectionModel.length === 0 && (
        <SpeedDial FabProps={{ color: "secondary" }} ariaLabel="SpeedDial basic example" sx={fabStyle} icon={<SpeedDialIcon />}>
          {[
            {
              icon: <GiFruitBowl size={24} />,
              name: "Browse Lines",
              onClick: () => navigate("/lines"),
            },
          ].map(action => (
            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.onClick} />
          ))}
        </SpeedDial>
      )}
    </>
  );
};

export default OrderDetailsFab;
