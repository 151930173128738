import GenericPage from "@templates/GenericPage";
import React, { FC, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormControl, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, CircularProgress } from "@mui/material";
import {
  Category,
  GetordersByStoreQueryVariables,
  ReportArticlesSelectionType,
  ReportType,
  StatusType,
  useGetAllCategoriesQuery,
  useGetReportLazyQuery,
  useGetordersByStoreLazyQuery,
} from "../../graphql/types";
import StoreContext from "@context/StoreContext";
import { openPrintWindow } from "@utils/misc";

const steps = [
  {
    label: "Report Type",
    description: `Select an option to generate report either by daily or weekly`,
  },
  {
    label: "Articles in report",
    description: "Select an option to generate all articles information or articles of a selected category ",
  },
];

const PrintPage: FC = () => {
  const { selectedStore, storeId } = useContext(StoreContext);
  const { data } = useGetAllCategoriesQuery();
  const categories = (data?.categories?.items as Category[]) || [];
  const activeCategories = categories.filter(category => category.name?.match(/Not/) === null);
  const [activeStep, setActiveStep] = useState(0);
  const [reportType, setReportType] = useState<string>("daily");
  const [categoryIds, setcategoryIds] = useState<string[]>([]);
  const [articleType, setArticleType] = useState<string>("latestOrder");
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [getReport] = useGetReportLazyQuery();
  const [getOrders] = useGetordersByStoreLazyQuery();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const resetForm = () => {
    setActiveStep(0);
    setReportType("daily");
    setcategoryIds([]);
    setArticleType("latestOrder");
  };

  const handlePrint = () => {
    setLoadingReport(true);
    if (articleType.toLowerCase() == ReportArticlesSelectionType.LatestOrder.toLowerCase()) {
      getOrders({
        variables: {
          storeId: storeId || "",
          offset: 0,
          limit: 10,
        } as GetordersByStoreQueryVariables,
        fetchPolicy: "cache-and-network",
        onCompleted: data => {
          const orders = data?.ordersByStore?.items || [];
          const submittedOrders = orders.filter(order => order?.status !== StatusType.InProgress);
          fetchReportContent(submittedOrders[0]?.id, submittedOrders[0]?.status === StatusType.PendingSubmission);
        },
      });
    } else {
      fetchReportContent();
    }
  };

  const fetchReportContent = (lastestOrderId?: number, isPendingOrder?: boolean) => {
    getReport({
      variables: {
        reportParams: {
          reportType: reportType as ReportType,
          articlesType: articleType as ReportArticlesSelectionType,
          storeId: storeId || "",
          categoryIds: categoryIds || [],
          storeName: selectedStore?.siteName || "",
          orderId: `${lastestOrderId || ""}`,
          isPendingOrder: !!isPendingOrder,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: data => {
        const fileContent = data.report?.item?.fileContent;
        openPrintWindow(fileContent || "");
        resetForm();
        setLoadingReport(false);
      },
      onError: () => {
        setLoadingReport(false);
        resetForm();
      },
    });
  };

  const handleReportTypeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportType((event.target as HTMLInputElement).value);
  };

  const handleArticlesTypeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArticleType((event.target as HTMLInputElement).value);
  };

  const handleCategorySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categoryId = event.target.value;

    const categoryItemIndex = categoryIds.indexOf(categoryId);

    if (categoryItemIndex > -1) {
      const selectedCategoryIds = [...categoryIds].splice(categoryItemIndex, 1);
      setcategoryIds(selectedCategoryIds);
    } else {
      setcategoryIds([...categoryIds, categoryId]);
    }
  };

  const isCategoryArticlesSelected = articleType !== "byCategory";

  return (
    <GenericPage title="Print" maxWidth="xl" actions={<></>}>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="Report Type">
            <StepLabel>Report Type</StepLabel>
            <StepContent>
              <Typography>Select an option to generate report either by daily or weekly</Typography>
              <Box sx={{ mb: 2 }}>
                <FormControl>
                  <RadioGroup aria-label="Report Type" value={reportType} name="radio-buttons-group" onChange={handleReportTypeSelection}>
                    <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                    <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    Continue
                  </Button>
                  <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key="Articles in report">
            <StepLabel optional={null}>Articles in report</StepLabel>
            <StepContent>
              <Typography>Select an option to generate all articles information or articles of a selected category</Typography>
              <Box sx={{ mb: 2 }}>
                <FormControl>
                  <RadioGroup aria-label="articles" value={articleType} name="radio-buttons-group" onChange={handleArticlesTypeSelection}>
                    <FormControlLabel value="latestOrder" control={<Radio />} label="Articles from last order" />
                    <FormControlLabel value="all" control={<Radio />} label="All Articles" />
                    <FormControlLabel value="byCategory" control={<Radio />} label="Custom Articles by Category" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <FormGroup>
                  {activeCategories.map(category => (
                    <FormControlLabel
                      key={category.id}
                      control={<Checkbox disabled={isCategoryArticlesSelected} onChange={handleCategorySelection} value={category.id} />}
                      label={category?.name}
                    />
                  ))}
                </FormGroup>
              </Box>
              <Box sx={{ mb: 2 }}>
                {!loadingReport ? (
                  <div>
                    <Button variant="contained" onClick={handlePrint} sx={{ mt: 1, mr: 1 }}>
                      Print
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                ) : (
                  <div>
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </GenericPage>
  );
};

export default PrintPage;
