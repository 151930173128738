import { Alert, Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { FC, ReactNode } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { OrderArticle } from "src/graphql/types";

interface Props {
  loading: boolean;
  readonly: boolean;
  rosterValid: boolean;
  failedArticles?: OrderArticle[];
  children?: ReactNode;
}

const OrderDetailsAlert: FC<Props> = props => {
  const { loading, readonly, rosterValid, failedArticles = [] } = props;
  return (
    <>
      <Alert severity="warning" sx={{ mb: 2, display: failedArticles.length ? "block" : "none" }}>
        Following articles are not available for the selected roster or for your store
        <Box>
          <List>
            {failedArticles.map((article, index) => {
              return (
                <ListItem key={index} sx={{ m: 0 }}>
                  <ListItemIcon>
                    <FiberManualRecordIcon sx={{ fontSize: "small", color: "rgba(0, 0, 0, 0.54)" }} />
                  </ListItemIcon>
                  <ListItemText primary={`${article.description} - ${article.articleNumber}`} secondary={null} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Alert>

      {!loading && readonly && <Alert severity="warning" sx={{ mb: 2 }}>{`The order you are viewing is ${status} and can't be edited`}</Alert>}
      {!rosterValid && !readonly && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Selected roster for this order is expired, please refresh browser and select new roster
        </Alert>
      )}
    </>
  );
};

export default OrderDetailsAlert;
