import StoreContext from "@context/StoreContext";
import { ShoppingCart } from "@mui/icons-material";
import { Badge, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import GenericListPage from "@templates/GenericListPage";
import { toKebabCase, toTitleCase } from "@utils/misc";
import { IconButton } from "gatsby-theme-material-ui";
import React, { FC, useContext } from "react";
import { useCart } from "react-use-cart";
import OrderContext from "@context/OrderContext";
import { Exact, useGetSubCategoriesQuery } from "../../graphql/types";
import { useNavigate } from "react-router-dom";

const CataloguePage: FC = () => {
  const { category, departmentId, categoryHierarchyNode = "" } = useParams();
  const { storeId = "" } = useContext(StoreContext);
  const { order } = useContext(OrderContext);
  const { isEmpty } = useCart();
  const navigate = useNavigate();
  const { loading, data } =
    category === "all"
      ? useGetSubCategoriesQuery({
          fetchPolicy: "no-cache",
          variables: { storeId } as Exact<{
            departmentHierarchyNode: string;
            categoryHierarchyNode: string;
            storeId: string;
          }>,
        })
      : useGetSubCategoriesQuery({
          fetchPolicy: "no-cache",
          variables: {
            departmentHierarchyNode: departmentId as string,
            categoryHierarchyNode,
            storeId,
          },
        });

  const subCategoriesResponse = data && data.subCategoriesWithFilters;
  const subCategories = subCategoriesResponse ? subCategoriesResponse.items || [] : [];

  const items = subCategories.map(subCategory => {
    if (!subCategory) {
      return {
        primary: "",
        secondary: "",
        to: "/",
      };
    }
    return {
      primary: `${toTitleCase(subCategory.name || "")}`,
      secondary: `${toTitleCase(subCategory.category || "")} - ${subCategory.numberOfArticles} available lines`,
      to: `/lines/${toKebabCase(category || "")}/${categoryHierarchyNode || 0}/${toKebabCase(subCategory.name || "")}/${subCategory.id}`,
    };
  });

  const title = category === "all" ? "All Categories" : toTitleCase(category);
  return (
    <GenericListPage
      items={items}
      loading={loading}
      breadcrumbs={[
        {
          to: `/catalogue/${toKebabCase(category)}/${categoryHierarchyNode}`,
          text: title,
        },
      ]}
      actions={
        <Stack sx={{ pl: 1 }} direction="row">
          <IconButton color="inherit" disabled={!order || !order.id} onClick={() => navigate(`/order/edit/${order.id}`)}>
            <Badge color="warning" variant="dot" invisible={isEmpty}>
              <ShoppingCart />
            </Badge>
          </IconButton>
        </Stack>
      }
      title={title}
    ></GenericListPage>
  );
};
export default CataloguePage;
