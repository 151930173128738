import { MenuItem, TextField } from "@mui/material";
import { dateFormat } from "@utils/misc";
import moment from "moment";
import React, { FC } from "react";
import { StoreOrderRoster } from "../../graphql/types";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | string;
  rosters: StoreOrderRoster[];
  readonly: boolean;
}

const OrderRosters: FC<Props> = props => {
  const { value, rosters, onChange, readonly } = props;

  return (
    <TextField
      sx={{ minWidth: 300 }}
      id="roster"
      select={!readonly}
      label="Order Roster"
      size="small"
      disabled={readonly}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth={true}
      variant="standard"
      InputProps={{
        disableUnderline: readonly,
      }}
      value={value || ""}
      onChange={onChange}
      defaultValue=""
    >
      {rosters.map(roster => (
        <MenuItem key={roster.scheduleId} value={roster.id || ""}>
          {`${moment(roster.deliveryDateTimeUtc).format(dateFormat)} - ${roster.sourceOfSupplyName}`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default OrderRosters;
