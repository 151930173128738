import { DataGrid, QtyInput } from "@components";
import React, { useContext, useMemo } from "react";
import {
  DataGridProProps,
  GridCellParams,
  GridRowClassNameParams,
  GridRowParams,
  GridRowSelectionModel,
  GridPaginationModel,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import { Article, GetCurrentOrderByStoreDocument, OrderArticle, useAddArticleMutation, useUpdateArticleMutation } from "../../graphql/types";
import { Button, Theme } from "@mui/material";
import { OrderItem } from "@utils/api";
import { useCart } from "react-use-cart";
import OrderContext from "@context/OrderContext";
import { badgeRenderer, currencyRenderer, tooltipRenderer } from "@utils/dataGrid";
import { transformLineToCartItem } from "@utils/orders";
import { useAuth } from "react-oidc-context";
import StoreContext from "@context/StoreContext";

interface Props {
  loading: boolean;
  currentOrderRosterExpired: boolean;
  subCategory: string;
  articles: Article[];
  subCategoryHierarchyNode: string;
  totalCount: number;
  selectionModel: GridRowSelectionModel;
  onRowSelect: (rowModel: GridRowSelectionModel) => void;
  onPaginationChange: (model: GridPaginationModel) => void;
}

const LineGrid = (props: Props) => {
  const { loading, currentOrderRosterExpired, subCategory, articles, subCategoryHierarchyNode, totalCount, onPaginationChange, selectionModel, onRowSelect } =
    props;
  const { addItem, inCart, getItem, updateItemQuantity, totalItems } = useCart();
  const { order } = useContext(OrderContext);
  const [addArticleMutation, { loading: addArticleInProgress }] = useAddArticleMutation();
  const [updateArticleMutation] = useUpdateArticleMutation();
  const { storeId } = useContext(StoreContext);
  const auth = useAuth();

  const columns: DataGridProProps["columns"] = useMemo(
    () => [
      {
        field: "quantity",
        headerName: `In Order`,
        width: 160,
        align: "right",
        type: "actions",
        cellClassName: "wpp--qty-cell",
        filterable: false,
        getActions: (params: GridRowParams<OrderArticle>) => {
          const item: OrderItem = getItem(`${params.row.articleNumber}`);
          const isNotAvailable = params.row.endOfSeason || params.row.temporarilyOutOfStock;
          if (!item || item.quantity < 1) {
            return [
              <Button
                variant="contained"
                color="primary"
                sx={{
                  minWidth: (theme: Theme) => theme.spacing(4),
                  px: 1,
                }}
                disabled={!order?.id || currentOrderRosterExpired || isNotAvailable}
                onClick={e => {
                  e.stopPropagation();
                  addArticleMutation({
                    variables: {
                      newArticle: {
                        orderId: String(order.id),
                        articleNumber: params.row.articleNumber,
                        quantity: 1,
                        createdBy: auth.user?.profile.sub || "",
                        storeId: storeId || "",
                      },
                    },
                    refetchQueries: [
                      {
                        query: GetCurrentOrderByStoreDocument,
                        variables: { storeId },
                      },
                    ],
                  });
                  addItem(transformLineToCartItem(params.row as OrderArticle));
                }}
                key="success"
              >
                Add to order
              </Button>,
            ];
          }

          return [
            <QtyInput
              id={`quantity${params.id}`}
              value={item.quantity}
              disabled={currentOrderRosterExpired}
              onChange={qty => {
                updateArticleMutation({
                  variables: {
                    article: {
                      orderId: String(order.id),
                      articleNumber: item.id,
                      quantity: qty,
                      modifiedBy: auth.user?.profile.sub || "",
                      storeId: storeId || "",
                    },
                  },
                });
                updateItemQuantity(item.id, qty);
              }}
              key="quantityInput"
            />,
          ];
        },
      },
      {
        field: "state",
        headerName: "",
        width: 40,
        sortable: false,
        filterable: false,
        renderCell: badgeRenderer,
      },
      {
        field: "lastOrderQuantity",
        headerName: "Last Order Quantity",
        width: 80,
        sortable: false,
        filterable: false,
      },
      // {
      //   field: "stockOnOrder",
      //   headerName: "Stock On Order",
      //   width: 80,
      //   sortable: false,
      //   filterable: false,
      // },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        valueGetter: ({ row }) => {
          const { description } = row;

          return subCategory && description.startsWith(row.subcategory) ? description.replace(`${row.subcategory} `, "") : description;
        },
        renderCell: tooltipRenderer,
        filterOperators: getGridStringOperators().filter(operator => operator.value == "contains"),
      },
      {
        field: "uom",
        headerName: "UOM",
        description: "Unit of Measure",
        align: "center",
        width: 50,
        filterable: false,
      },
      {
        field: "orderMultiple",
        headerName: "OM",
        align: "center",
        width: 50,
        filterable: false,
      },
      {
        field: "unitCost",
        headerName: "Unit Cost",
        width: 90,
        type: "number",
        filterable: false,
        renderCell: params => currencyRenderer(params),
      },
      {
        field: "unitRrp",
        headerName: "RRP",
        description: "Regular Retail Price",
        width: 90,
        type: "number",
        filterable: false,
        renderCell: params => currencyRenderer(params),
      },
      {
        field: "cartonCost",
        headerName: "Cart Cost",
        width: 90,
        type: "number",
        filterable: false,
        renderCell: params => currencyRenderer(params),
      },
      // {
      //   field: "onPromo",
      //   headerName: "On Promo",
      //   width: 100,
      //   type: "boolean",
      //   filterable: false,
      //   hide: true,
      // },
      {
        field: "articleNumber",
        headerName: "Article #",
        width: 90,
        type: "number",
        filterOperators: getGridNumericOperators().filter(operator => operator.value === "="),
      },
      // {
      //   field: "available",
      //   headerName: "Available?",
      //   width: 90,
      //   type: "boolean",
      //   filterable: false,
      //   hide: true,
      // },
      {
        field: "plu",
        headerName: "PLU",
        width: 70,
        type: "number",
        filterOperators: getGridNumericOperators().filter(operator => operator.value === "="),
      },
      {
        field: "category",
        headerName: "Category",
        width: 150,
        type: "singleSelect",
        valueOptions: [],
        filterOperators: getGridStringOperators().filter(operator => operator.value == "contains"),
      },
      {
        field: "subCategory",
        headerName: "Subcategory",
        width: 150,
        type: "singleSelect",
        valueOptions: [],
        filterOperators: getGridStringOperators().filter(operator => operator.value == "contains"),
      },
      {
        field: "countryOfOrigin",
        description: "Country of Origin",
        headerName: "COO",
        width: 150,
        type: "singleSelect",
        filterable: false,
      },
    ],
    [order?.id, totalItems, currentOrderRosterExpired]
  );

  return (
    <DataGrid
      loading={loading || addArticleInProgress}
      checkboxSelection={false}
      onRowSelectionModelChange={newRowSelectionModel => {
        onRowSelect(newRowSelectionModel);
      }}
      rowSelectionModel={selectionModel}
      getRowId={(row: Article) => row.articleNumber}
      getCellClassName={(params: GridCellParams) => {
        const row = params.row;
        const notAvailable = row.endOfSeason || row.temporarilyOutOfStock;
        if (notAvailable && params.field !== "state") return "mp--row--notAvailable";
        return "";
      }}
      getRowClassName={(params: GridRowClassNameParams<Article>) => {
        const row = params.row;
        const hasQty = inCart(row.articleNumber);
        if (hasQty) return "mp--row--inOrder";
        return "";
      }}
      onCellClick={(_, event) => {
        event.stopPropagation();
      }}
      isRowSelectable={params => params.row.available}
      columns={columns}
      rows={articles}
      pagination={!subCategoryHierarchyNode}
      rowCount={totalCount}
      onPaginationChange={onPaginationChange}
      maxPageSize
    />
  );
};

export default LineGrid;
