import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import GenericPage from "@templates/GenericPage";
import { Box, BoxProps, Modal, Stack, Theme } from "@mui/material";
import { ProductSearch } from "@components";
import { Item, useCart } from "react-use-cart";
import { alpha } from "@mui/system";
import moment from "moment";
import OrderContext from "@context/OrderContext";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  OrderDetail,
  StoreOrderRoster,
  useGetRosterLazyQuery,
  useUpdateOrderMutation,
  useDeleteArticlesMutation,
  useSubmitOrderMutation,
  useCopyOrderMutation,
  CopyOrderMutation,
  GetCurrentOrderByStoreDocument,
  OrderArticle,
} from "../../graphql/types";
import StoreContext from "@context/StoreContext";
import { useAuth } from "react-oidc-context";
import { dateFormat, dateTimeFormat } from "@utils/misc";
import OrderRosters from "./OrderRosters";
import OrderDetailsGrid from "./OrderDetailsGrid";
import OrderRostersModal from "./OrderRostersModal";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import OrderDetailsActions from "./OrderDetailsActions";
import OrderDetailsFab from "./OrderDetailsFab";
import OrderDetailsAlert from "./OrderDetailsAlert";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

const style: BoxProps["sx"] = theme => ({
  position: "absolute" as const,
  top: theme.spacing(2),
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
  maxWidth: 560,
  zIndex: 1000,
  p: 2,
});

interface Props {
  order: OrderDetail;
  items: Item[];
  loading: boolean;
  totalUniqueItems: number;
  totalItems: number;
  totalCost: number;
  totalGp: number;
  totalRrp: string;
  rosterId: number;
  isPendingSubmissionOrder: boolean;
  isReadonly: boolean;
  failedArticles?: OrderArticle[];
}

const OrderDetails: FC<Props> = ({
  order,
  items,
  loading,
  totalCost,
  totalItems,
  totalUniqueItems,
  totalGp,
  totalRrp,
  isPendingSubmissionOrder,
  isReadonly,
  failedArticles,
}) => {
  const [rosterId, setRosterId] = useState(0);
  const [rosterValid, setRosterValid] = useState(false);
  const [rosters, setRosters] = useState<StoreOrderRoster[]>([]);
  const { storeId } = useContext(StoreContext);
  const { removeItem } = useCart();
  const auth = useAuth();
  const { orderId = "" } = useParams();
  const [getRosters] = useGetRosterLazyQuery();
  const appInsights = useAppInsightsContext();
  const trackSubmitOrder = useTrackEvent(appInsights, "SubmitOrder", {});
  const trackSubmitFailure = useTrackEvent(appInsights, "SubmitFailure", {});

  const [openRostersModal, setOpenRostersModal] = React.useState(false);
  const [openProductSearch, setProductSearch] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [deleteArticlesMutation] = useDeleteArticlesMutation();

  const { order: currentOrder, setOrder } = useContext(OrderContext);
  const { enqueueSnackbar } = useSnackbar();
  const readonly = isReadonly;
  const navigate = useNavigate();

  useEffect(() => {
    setRosterId(order.rosterId);
  }, [order]);

  useEffect(() => {
    if (!storeId) return;
    getRosters({
      variables: {
        offset: 0,
        limit: 50,
        siteNumber: storeId || "",
      },
      onCompleted: data => {
        const rosters = data.storeOrderRostersBySite?.items || [];
        const filteredRosters = rosters?.filter(roster => roster?.isActive);
        setRosters(filteredRosters as StoreOrderRoster[]);
      },
    });
  }, [storeId]);

  useEffect(() => {
    const currentRoster = rosters?.find(roster => roster.id === order.rosterId);
    setRosterValid(!!currentRoster);
  }, [rosters, order]);

  const handleRemove = useCallback(() => {
    selectionModel.forEach(item => removeItem(item as string));
    setSelectionModel([]);
    deleteArticlesMutation({
      variables: {
        orderId,
        articleNumbers: selectionModel.map(article => String(article)),
        storeId: storeId || "",
      },
      refetchQueries: [
        {
          query: GetCurrentOrderByStoreDocument,
          variables: { storeId },
        },
      ],
    });
  }, [selectionModel, orderId]);

  const [updateOrderMutation] = useUpdateOrderMutation();
  const [copyOrderMutation] = useCopyOrderMutation();
  const [submitOrderMutation, { loading: submitInProgress }] = useSubmitOrderMutation({
    update(cache, { data }) {
      if (data?.submitOrder?.hasErrors) {
        return;
      }
      setOrder({} as OrderDetail);
      // evicting cache so that we can get latest stock on order quantities
      cache.evict({ fieldName: "articles" });
      cache.evict({ fieldName: "articlesBySubCategory" });
      cache.evict({ fieldName: "currentOrderByStore" });
      cache.evict({ fieldName: "ordersByStore" });
    },
  });

  const totalItemsInOrder = readonly ? order?.numberOfCartons : totalItems;
  const totalUniqueItemsInOrder = readonly ? order?.articles?.length : totalUniqueItems;

  const handleSend = useCallback(async () => {
    trackSubmitOrder({
      orderId,
      user: auth.user?.profile.sub,
      storeId,
    });

    submitOrderMutation({
      variables: {
        orderId,
        storeId: storeId || "",
        submittedBy: auth.user?.profile.sub || "",
      },
      onCompleted: async submitOrderResponse => {
        const hasErrors = submitOrderResponse.submitOrder?.hasErrors;
        console.log(submitOrderResponse);
        if (hasErrors) {
          trackSubmitFailure({
            orderId,
            user: auth.user?.profile.sub,
            storeId,
          });
          enqueueSnackbar("Order Submission failed", { variant: "error" });
          await navigate("/orders/");
          return;
        }

        enqueueSnackbar("Order Submitted", { variant: "default" });

        await navigate("/orders/");
      },
    });
  }, [orderId, storeId]);

  const handleRosterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedRosterId = Number(event.target.value);
      setRosterId(selectedRosterId);
      const roster = rosters.find(r => r.id === selectedRosterId);
      updateOrderMutation({
        variables: {
          orderToUpdate: {
            modifiedBy: auth.user?.profile.sub || "",
            rosterId: selectedRosterId,
            sourceOfSupplyName: roster?.sourceOfSupplyName || "",
            sourceOfSupplyId: roster?.sourceOfSupplyNumber || "",
            deliveryDateTimeUtc: roster?.deliveryDateTimeUtc || "",
            rosterCutOffDateTimeUtc: roster?.cutOffDateTimeUtc || "",
            orderId,
          },
        },
      });
    },
    [rosters]
  );

  const title = isPendingSubmissionOrder || !isReadonly ? `Pending Order` : `Order: ${order?.id}`;
  return (
    <GenericPage
      maxWidth="xl"
      actions={
        <OrderDetailsActions
          totalCost={totalCost}
          totalRrp={totalRrp}
          readonly={readonly}
          hasOpenOrder={!!currentOrder.id}
          rosters={rosters}
          onCopyOrder={() => setOpenRostersModal(true)}
          onSubmitOrder={handleSend}
          onRosterNotValid={() => setRosterValid(false)}
          items={items}
          order={order}
        />
      }
      title={title}
      fab={<OrderDetailsFab selectionModel={selectionModel} onDeleteArticles={handleRemove} readonly={readonly} />}
    >
      <Stack sx={{ pb: 2 }} direction="row" justifyContent="space-between">
        <OrderRosters
          value={readonly ? `${moment(order.deliveryDate).format(dateFormat)} - ${order.sourceOfSupplyName}` : rosterId}
          onChange={handleRosterChange}
          readonly={readonly}
          rosters={rosters}
        />
      </Stack>

      <OrderDetailsAlert loading={loading} rosterValid={rosterValid} readonly={readonly} failedArticles={failedArticles} />

      <OrderDetailsGrid
        totalItems={totalItemsInOrder}
        totalUniqueItems={totalUniqueItemsInOrder}
        totalCost={totalCost}
        totalRrp={totalRrp}
        totalGp={totalGp}
        readonly={readonly}
        orderId={orderId || ""}
        items={items}
        loading={loading || submitInProgress}
        selectionModel={selectionModel}
        handleSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
      />

      <OrderRostersModal
        open={openRostersModal}
        onClose={() => setOpenRostersModal(false)}
        onRosterSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRosterId(Number(event.target.value));
        }}
        rosters={rosters}
        rosterId={rosterId}
        onSubmit={() => {
          const roster = rosters.find(roster => roster.id === rosterId);
          setOpenRostersModal(false);
          copyOrderMutation({
            variables: {
              orderInput: {
                orderId: String(orderId),
                isPendingOrder: isPendingSubmissionOrder || !isReadonly,
                rosterId,
                storeId: storeId || "",
                deliveryDateTimeUtc: roster?.deliveryDateTimeUtc || "",
                sourceOfSupplyId: roster?.sourceOfSupplyNumber || "",
                sourceOfSupplyName: roster?.sourceOfSupplyName || "",
                rosterCutOffDateTimeUtc: roster?.cutOffDateTimeUtc || "",
                createdBy: auth.user?.profile.sub || "",
              },
            },
            refetchQueries: [{ query: GetCurrentOrderByStoreDocument, variables: { storeId } }],
            onCompleted: (data: CopyOrderMutation) => {
              if (data?.copyOrder?.hasErrors) {
                return;
              }
              const newOrder = data?.copyOrder?.item;
              const orderId = newOrder?.id;
              const failedArticles = newOrder?.failedArticles;
              navigate(`/order/edit/${orderId}/`, {
                state: { failedArticles },
              });
            },
          });
        }}
      />
      <Modal
        open={openProductSearch}
        onClose={() => setProductSearch(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: (theme: Theme) => alpha(theme.palette.background.paper, 0.9),
          },
        }}
      >
        <Box sx={style}>
          <ProductSearch onAddToCart={() => setProductSearch(false)} />
        </Box>
      </Modal>
    </GenericPage>
  );
};

export default OrderDetails;
