import { Box } from "@mui/material";
import GenericPage from "@templates/GenericPage";
import React from "react";

const Support = () => {
  return (
    <GenericPage title="Support" maxWidth="xl" actions={<></>}>
      <Box>Coming soon ...</Box>
    </GenericPage>
  );
};

export default Support;
