import { Box, useTheme } from "@mui/material";
import { addDaysToDate } from "@utils/misc";
import moment from "moment";
import React from "react";
import { CalculatedDates } from "src/graphql/types";

interface Props {
  forecastDates: CalculatedDates;
  weekNumber: number;
}

const GroupHeaderCell = (props: Props) => {
  const theme = useTheme();
  const { forecastDates, weekNumber } = props;
  const start = 7 * (weekNumber - 1);
  const end = 7 * weekNumber - 1;
  const forecastStartDate = moment(addDaysToDate(forecastDates?.startDate, start)).format("MMM DD");
  const forecastEndDate = moment(addDaysToDate(forecastDates.startDate, end)).format("MMM DD");
  const salesStartDate = moment(addDaysToDate(forecastDates?.saleStartDate, start)).format("MMM DD");
  const saleEndDate = moment(addDaysToDate(forecastDates.saleStartDate, end)).format("MMM DD");

  return (
    <Box style={{ fontSize: "12px", fontWeight: 500 }}>
      <Box
        sx={{
          display: "inline",
          color: theme.palette.primary.main,
        }}
      >
        {`FORECAST WEEK ${forecastStartDate} - ${forecastEndDate}`}
      </Box>
      <Box sx={{ color: "#B5B5B5", display: "inline" }}>{` VS. ACTUAL WEEK ${salesStartDate} - ${saleEndDate}`}</Box>
    </Box>
  );
};

export default GroupHeaderCell;
