import React from "react";
import { CalculatedDates } from "src/graphql/types";
import GroupHeaderCell from "./GroupHeaderCell";
import { GridColumnGroupingModel } from "@mui/x-data-grid-pro";

const getGridGroups = (calcDates: CalculatedDates, isDc: boolean): GridColumnGroupingModel => {
  const weekDays = ["mon", "tue", "wed", "thur", "fri", "sat", "sun"];
  const fields = [];
  const forecastDaysCount = 13;

  for (let dayIndex = 0; dayIndex <= forecastDaysCount; dayIndex++) {
    const weekDayIndex = dayIndex % 7;
    fields.push({
      field: `${weekDays[weekDayIndex]}-${dayIndex}`,
    });
  }
  const forecastGroupHeaders = [
    {
      groupId: "WEEK1FORECAST",
      description: "",
      headerClassName: "groupHeader groupHeader__forecast",
      children: fields.slice(0, 7),
      renderHeaderGroup: () => <GroupHeaderCell forecastDates={calcDates} weekNumber={1} />,
    },
    {
      groupId: "WEEK2FORECAST",
      description: "WEEK2FORECAST",
      headerClassName: "groupHeader groupHeader__forecast",
      children: fields.slice(7),
      renderHeaderGroup: () => <GroupHeaderCell forecastDates={calcDates} weekNumber={2} />,
    },
  ];

  return [
    {
      groupId: "Line Details",
      description: "",
      headerClassName: "groupHeader",
      children: [
        { field: "description" },
        { field: "pluCode" },
        { field: "plus" },
        { field: "uom" },
        { field: "articleNumber" },
        { field: "category" },
        { field: "subCategory" },
      ],
    },
    ...forecastGroupHeaders,
    {
      groupId: "WEEK TOTALS",
      description: "",
      headerClassName: "groupHeader",
      children: isDc
        ? [{ field: "week1ActualTotal" }, { field: "week2ActualTotal" }, { field: "week1Total" }, { field: "week2Total" }]
        : [{ field: "week1Total" }, { field: "week2Total" }],
    },
  ];
};

export default getGridGroups;
