import React from "react";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CalculatedDates, ForecastQuantity, ReportType, StoreForecast } from "src/graphql/types";
import { addDaysToDate } from "@utils/misc";
import moment from "moment";
import ForecastCell from "./ForecastCell";
import EditForecastCell from "./EditForecastCell";
import ForecastCellHeader from "./ForecastCellHeader";
import Box from "@mui/material/Box";

const renderForecastCell = (params: GridRenderCellParams, storeForecasts: StoreForecast[], isDc: boolean) => {
  const column = params.colDef;
  const currentStoreForecast = storeForecasts.find(forecast => forecast.id == params.row.id);
  const fieldText = column.field;
  const forecastDayIndex = Number(fieldText?.split("-")[1]);
  const forecast = currentStoreForecast?.forecast[Number(forecastDayIndex)] as ForecastQuantity;
  return <ForecastCell actualQuantity={forecast.quantity || ""} modifiedQuantity={forecast.enteredQty || ""} sale={forecast.sale || ""} isDc={isDc} />;
};

const editForecastCell = (params: GridRenderCellParams, storeForecasts: StoreForecast[], forecastTo: ForecastQuantity, isDc: boolean) => (
  <EditForecastCell params={params} storeForecasts={storeForecasts} forecastTo={forecastTo} isDc={isDc} />
);

const renderTotalCell = (storeForecast: StoreForecast, weekNumber: number, isDc: boolean) => {
  const start = 7 * (weekNumber - 1);
  const end = 7 * weekNumber;

  const weeklyForecasts = storeForecast.forecast.slice(start, end);
  const { totalActualForecast, totalChangeInForecast, totalSales } = weeklyForecasts.reduce(
    (accumulator, current) => {
      accumulator.totalActualForecast += Number(current.quantity);
      accumulator.totalChangeInForecast += getActualQuantity(Number(current.quantity), Number(current.enteredQty), isDc);
      accumulator.totalSales += Number(current.sale);

      return accumulator;
    },
    {
      totalActualForecast: 0,
      totalChangeInForecast: 0,
      totalSales: 0,
    }
  );

  return (
    <ForecastCell
      actualQuantity={totalActualForecast.toString()}
      modifiedQuantity={totalChangeInForecast.toString()}
      sale={totalSales.toString()}
      isDc={isDc}
    />
  );
};

const getActualQuantity = (quantity: number, enteredQuantity: number, isDc: boolean) => {
  if (isDc) {
    return enteredQuantity > 0 ? enteredQuantity : quantity;
  }
  return enteredQuantity;
};

const getGridColumns = (storeForecasts: any[], calcDates: CalculatedDates, isDc: boolean): GridColDef[] => {
  const forecastColumns = getForecastColumns(storeForecasts, calcDates, isDc);
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "groupHeaderChild",
      width: 300,
    },
    {
      field: "uom",
      headerName: "UOM",
      headerClassName: "groupHeaderChild",
      width: 58,
      sortable: false,
    },
  ];
  if (!isDc) {
    columns.push({
      field: "pluCode",
      headerName: "PLU#",
      headerClassName: "groupHeaderChild",
    });
  }
  columns.push(
    {
      field: "articleNumber",
      headerName: "Article#",
      headerClassName: "groupHeaderChild",
      width: 100,
      sortable: false,
    },
    {
      field: "category",
      headerName: "CATEGORY",
      headerClassName: "groupHeaderChild",
    },
    {
      field: "subCategory",
      headerName: "SUBCATEGORY",
      headerClassName: "groupHeaderChild",
    },
    ...forecastColumns,
    {
      field: "week1Total",
      headerName: "Week 1 Total",
      headerClassName: "groupHeaderChild",
      renderHeader: () => <b>Week 1 Total</b>,
      renderCell: params => {
        const current = storeForecasts.find(forecast => forecast.id == params.row.id);
        if (current != undefined) {
          return renderTotalCell(current, 1, isDc);
        }
      },
    },
    {
      field: "week2Total",
      headerName: "Week 2 Total",
      headerClassName: "groupHeaderChild",
      renderHeader: () => <b>Week 2 Total</b>,
      renderCell: params => {
        const current = storeForecasts.find(forecast => forecast.id == params.row.id);
        if (current != undefined) {
          return renderTotalCell(current, 2, isDc);
        }
      },
    }
  );

  if (isDc) {
    columns.push({
      field: "week1ActualTotal",
      headerName: "Week 1 Actual Total",
      headerClassName: "groupHeaderChild",
      renderHeader: () => getActualTotalHeader("Week 1"),
      renderCell: params => {
        return <ForecastCell actualQuantity={params.row.totalSales.toString()} modifiedQuantity={"0"} sale={"0"} isDc={true} />;
      },
    });
    columns.push({
      field: "week2ActualTotal",
      headerName: "Week 2 Actual Total",
      headerClassName: "groupHeaderChild",
      renderHeader: () => getActualTotalHeader("Week 2"),
      renderCell: params => {
        return <ForecastCell actualQuantity={params.row.totalSales.toString()} modifiedQuantity={"0"} sale={"0"} isDc={true} />;
      },
    });
  }
  return columns;
};

const getActualTotalHeader = (text: string) => {
  return (
    <Box sx={{ fontWeight: "bold" }}>
      <div style={{ height: "20px", fontSize: "12px" }}>{text}</div>
      <span style={{ fontWeight: "bold" }}>Actual Total</span>
    </Box>
  );
};

const getForecastColumns = (storeForecasts: StoreForecast[], calcDates: CalculatedDates, isDc: boolean) => {
  const weekDays = ["mon", "tue", "wed", "thur", "fri", "sat", "sun"];
  const fields = [];
  const forecastColumns: GridColDef[] = [];
  const forecastDaysCount = 13;
  const startDate = calcDates.startDate;

  for (let dayIndex = 0; dayIndex <= forecastDaysCount; dayIndex++) {
    const currentDate = addDaysToDate(startDate, dayIndex);
    const weekDayIndex = dayIndex % 7;
    fields.push({
      field: `${weekDays[weekDayIndex]}-${dayIndex}`,
    });

    forecastColumns.push({
      field: `${weekDays[weekDayIndex]}-${dayIndex}`,
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "groupHeaderChild groupHeaderChild__forecast",
      renderCell: (params: GridRenderCellParams) => renderForecastCell(params, storeForecasts, isDc),
      cellClassName: "forecast",
      editable: !calcDates?.nextEnabled,
      renderEditCell: (params: GridRenderCellParams) => {
        const currentStoreForecast = storeForecasts.find(forecast => forecast.id == params.row.id);
        const column = params.colDef;
        const fieldText = column.field;
        const forecastDayIndex = Number(fieldText?.split("-")[1]);
        const forecastTo = currentStoreForecast?.forecast[Number(forecastDayIndex)] as ForecastQuantity;
        if (forecastTo.enteredQty == "" && forecastTo.quantity == "") return;
        return editForecastCell(params, storeForecasts, forecastTo, isDc);
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const weekDay = params.colDef.field.split("-")[0];
        const month = moment(currentDate).format("MM");

        return <ForecastCellHeader currentDate={currentDate} weekDay={weekDay} month={month} />;
      },
    });
  }

  return forecastColumns;
};

export default getGridColumns;
