import React, { FC, useContext } from "react";
import { DataGrid, QtyInput } from "@components";
import {
  FooterPropsOverrides,
  GridActionsCellItem,
  GridRowParams,
  GridRowSelectionModel,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import DataGridToolBar from "@components/DataGrid/DataGridToolBar";
import DataGridOrderSummary from "@components/DataGrid/DataGridOrderSummary";
import { Item, useCart } from "react-use-cart";
import { OrderItem } from "@utils/api";
import { OrderArticle, OrderArticleStatus, useDeleteArticleMutation, useUpdateArticleMutation } from "../../graphql/types";
import { useAuth } from "react-oidc-context";
import { currencyRenderer, tooltipRenderer } from "@utils/dataGrid";
import { toCurrency } from "@utils/locale";
import { Delete } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { gql } from "@apollo/client";
import StoreContext from "@context/StoreContext";
import Order from "../../enums/order";

interface Props {
  readonly: boolean;
  totalItems: number;
  totalUniqueItems: number;
  totalCost: number;
  totalRrp: string;
  totalGp: number;
  orderId: string;
  loading: boolean;
  items: Item[];
  selectionModel: GridRowSelectionModel;
  handleSelectionModelChange: (newModel: GridRowSelectionModel) => void;
}

const OrderDetailsGrid: FC<Props> = props => {
  const { readonly, totalItems, totalUniqueItems, totalCost, totalRrp, totalGp, orderId, loading, items, handleSelectionModelChange, selectionModel } = props;
  const { removeItem, updateItemQuantity, getItem } = useCart();

  const [updateArticleMutation] = useUpdateArticleMutation({
    update(cache, { data }) {
      if (data?.updateArticleInOrder?.hasErrors) {
        return;
      }
      cache.evict({ fieldName: "currentOrderByStore" });
    },
  });

  const [deleteArticleMutation, { loading: deleteInProgress }] = useDeleteArticleMutation({
    update(cache, { data }) {
      if (data?.deleteArticleInOrder?.hasErrors) {
        return;
      }
      cache.evict({ fieldName: "currentOrderByStore" });
    },
  });
  const { storeId } = useContext(StoreContext);

  const auth = useAuth();
  return (
    <div>
      {totalItems < Order.MINIMUM_CARTON_NUMBER && (
        <p style={{ color: "#ed6c02" }}>* Total number of cartons should be {Order.MINIMUM_CARTON_NUMBER} or more</p>
      )}
      <DataGrid
        getRowId={row => (readonly ? row.articleNumber : row.id)}
        onRowSelectionModelChange={handleSelectionModelChange}
        checkboxSelection={!readonly}
        rowSelectionModel={selectionModel}
        slots={{
          toolbar: DataGridToolBar,
          footer: DataGridOrderSummary,
        }}
        slotProps={{
          footer: {
            totalItems,
            totalUniqueItems,
            totalCost,
            totalRrp,
            totalGp,
          } as FooterPropsOverrides,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              totalOrderedUnits: readonly,
              acknowledgedUnits: readonly,
              lineStatuses: readonly,
              actions: !readonly,
            },
          },
        }}
        columns={[
          !readonly
            ? {
                field: "quantity",
                headerName: "Requested Qty",
                width: 140,
                type: "actions",
                cellClassName: "wpp--qty-cell",
                filterable: false,
                renderCell: params => {
                  const item: OrderItem = getItem(`${params.id}`);
                  if (!item) return [];
                  return [
                    <QtyInput
                      key={`${params.id}-qty`}
                      id={`quantity${params.id}`}
                      value={params.row.quantity}
                      onChange={qty => {
                        updateArticleMutation({
                          variables: {
                            article: {
                              orderId,
                              articleNumber: item.id,
                              quantity: qty,
                              modifiedBy: auth.user?.profile.sub || "",
                              storeId: storeId || "",
                            },
                          },
                          onCompleted: () => {},
                        });
                        updateItemQuantity(item.id, qty);
                      }}
                    />,
                  ];
                },
              }
            : {
                field: "requestedQuantity",
                headerName: "Ordered Qty",
                width: 100,
                align: "center",
                type: "number",
                filterable: false,
              },
          {
            field: "orderMultiple",
            headerName: "Ordered OM",
            width: 70,
            align: "center",
            sortable: false,
            filterable: false,
          },
          {
            field: "uom",
            headerName: "UOM",
            description: "Unit of Measure",
            width: 70,
            sortable: false,
            align: "center",
            filterable: false,
          },
          {
            field: "totalOrderedUnits",
            headerName: "Total Ordered Units",
            width: 100,
            sortable: false,
            align: "center",
            filterable: false,
            valueGetter: params => {
              const item: OrderArticle = params.row;
              return item ? item.orderMultiple * item.requestedQuantity : 0;
            },
          },
          {
            field: "acknowledgedUnits",
            headerName: "Total Ackn. Units",
            description: "Total Acknowledged Units",
            width: 70,
            sortable: false,
            filterable: false,
          },
          // {
          //   field: "invoicedQuantity",
          //   headerName: "Total Inv. Units",
          //   description: "Total Invoiced  Units",
          //   width: 70,
          //   align: "right",
          //   type: "number",
          //   sortable: false,
          //   filterable: false,
          //   hide: !readonly,
          //   valueGetter: params => {
          //     const item: OrderArticle = params.row;
          //     return item ? item.invoicedQuantity * item.invoicedOrderMultiple : 0;
          //   },
          // },
          // {
          //   field: "invoiceReferenceNumber",
          //   headerName: "Invoice Ref",
          //   width: 90,
          //   align: "right",
          //   type: "number",
          //   sortable: false,
          //   filterable: false,
          //   hide: !readonly,
          // },
          // {
          //   field: "stockOnOrder",
          //   headerName: "Stock on Order",
          //   width: 130,
          //   sortable: false,
          //   type: "number",
          //   filterable: false,
          // },
          {
            field: "description",
            headerName: "Description",
            width: 170,
            renderCell: tooltipRenderer,
            filterOperators: getGridStringOperators().filter(operator => operator.value == "contains"),
          },
          {
            field: "unitCost",
            headerName: "Unit Cost",
            width: 90,
            type: "number",
            sortable: false,
            filterable: false,
            renderCell: params => {
              return currencyRenderer(params);
            },
          },

          {
            field: "unitRrp",
            headerName: "Unit RRP",
            description: "Regular Retail Price",
            width: 90,
            type: "number",
            sortable: false,
            filterable: false,
            renderCell: params => currencyRenderer(params),
          },
          {
            field: "cartonCost",
            headerName: "Carton Cost",
            width: 120,
            type: "number",
            sortable: false,
            filterable: false,
            valueFormatter: params => (params.value ? toCurrency(+params.value) : toCurrency(0)),
          },

          {
            field: "lineTotal",
            headerName: "Line Total",
            width: 90,
            type: "number",
            filterable: false,
            sortable: false,
            valueGetter: params => {
              const item: OrderItem = readonly ? params.row : getItem(`${params.id}`);
              return item ? item.cartonCost * item.quantity : 0;
            },
            valueFormatter: params => (params.value ? toCurrency(+params.value) : toCurrency(0)),
          },
          {
            field: "articleNumber",
            headerName: "Article #",
            width: 90,
            type: "number",
            sortable: false,
            renderCell: ({ value }) => `${value}`,
            filterOperators: getGridNumericOperators().filter(operator => operator.value === "="),
          },
          {
            field: "countryOfOrigin",
            headerName: "COO",
            description: "Country of Origin",
            width: 150,
            sortable: false,
            filterable: false,
          },
          {
            field: "lineStatuses",
            headerName: "Reason",
            sortable: false,
            width: 400,
            renderCell: params => (
              <div>
                {(params.value || []).map((item: OrderArticleStatus, index: number) => {
                  return item.description ? (
                    <Typography sx={{ "font-size": "0.825rem", "white-space": "normal" }} key={index}>
                      {item.description}
                    </Typography>
                  ) : (
                    <></>
                  );
                })}
              </div>
            ),
          },
          {
            field: "actions",
            headerName: "Actions",
            headerAlign: "right",
            align: "right",
            flex: 1,
            minWidth: 120,
            sortable: false,
            type: "actions",

            getActions: (params: GridRowParams) => [
              ...(!readonly
                ? [
                    <GridActionsCellItem
                      label="Delete"
                      icon={<Delete color="error" />}
                      onClick={() => {
                        deleteArticleMutation({
                          variables: {
                            orderId,
                            articleNumber: params.row.articleNumber,
                            storeId: storeId || "",
                          },
                        });
                        removeItem(`${params.row.articleNumber}`);
                      }}
                      key="delete"
                      onResize={() => {}}
                      onResizeCapture={() => {}}
                    />,
                  ]
                : []),
            ],
          },
        ]}
        loading={loading || deleteInProgress}
        rows={items ? items : []}
      />
    </div>
  );
};

export default OrderDetailsGrid;
