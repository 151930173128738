import Modal from "@components/Modal";
import { Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { StoreOrderRoster } from "../../graphql/types";
import OrderRosters from "./OrderRosters";

interface Props {
  open: boolean;
  onClose: () => void;
  rosters: StoreOrderRoster[];
  rosterId: number;
  onRosterSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const OrderRostersModal: FC<Props> = props => {
  const { open, onClose, rosters, onRosterSelect, rosterId, onSubmit } = props;
  const filteredRosters = rosters?.filter(roster => roster?.isActive);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="title" aria-describedby="description">
      <Typography id="title" variant="h6" component="h2">
        Order Rosters
      </Typography>
      <Typography id="description" sx={{ mt: 2, mb: 2 }}>
        We need to select a roster to create a new order from an old order.
      </Typography>
      <OrderRosters readonly={false} value={rosterId} rosters={filteredRosters} onChange={onRosterSelect} />
      <Button variant="contained" onClick={onSubmit} sx={{ mt: 3 }}>
        Submit
      </Button>
    </Modal>
  );
};

export default OrderRostersModal;
