import OrderContext from "@context/OrderContext";
import React, { useContext, useMemo } from "react";
import { useCart } from "react-use-cart";
import { calcGPPercent, calcRrpTotal } from "@utils/orders";
import OrderDetails from "./OrderDetails";
import { useLocation } from "react-router";

const OrderEdit = () => {
  const { items, totalItems, totalUniqueItems, cartTotal } = useCart();
  const { order, loading } = useContext(OrderContext);
  const location = useLocation();
  const failedArticles = location?.state?.failedArticles || [];
  const totalRrp = useMemo(() => calcRrpTotal(items), [cartTotal]);
  const totalGp = useMemo(() => calcGPPercent(cartTotal, Number(totalRrp)), [cartTotal]);

  return (
    <OrderDetails
      order={order}
      loading={loading}
      items={items}
      totalCost={cartTotal}
      totalUniqueItems={totalUniqueItems}
      totalItems={totalItems}
      totalGp={Number(totalGp)}
      totalRrp={totalRrp}
      rosterId={order.rosterId}
      failedArticles={failedArticles}
      isPendingSubmissionOrder={false}
      isReadonly={false}
    ></OrderDetails>
  );
};

export default OrderEdit;
