import { DataGrid } from "@components";
import OrderContext from "@context/OrderContext";
import StoreContext from "@context/StoreContext";
import { ShoppingCart } from "@mui/icons-material";
import { Alert, Badge, Link } from "@mui/material";
import { GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import GenericPage from "@templates/GenericPage";
import { dateFormat, dateTimeFormat } from "@utils/misc";
import { Button, IconButton } from "gatsby-theme-material-ui";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { OrderDetail, useCreateOrderMutation, useGetRosterLazyQuery, StoreOrderRoster, GetCurrentOrderByStoreDocument } from "../../graphql/types";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";

const RosterPage: FC = () => {
  const [showRosterSelectionError, setshowRosterSelectionError] = useState(false);
  const [rosters, setRosters] = useState<StoreOrderRoster[]>([]);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const { storeId } = useContext(StoreContext);
  const { order: currentOrder } = useContext(OrderContext);
  const auth = useAuth();
  const { isEmpty } = useCart();
  const navigate = useNavigate();
  const [getRosters, { loading: rostersLoading }] = useGetRosterLazyQuery();

  useEffect(() => {
    if (!storeId) return;
    getRosters({
      variables: {
        offset: 0,
        limit: 50,
        siteNumber: storeId,
      },
      nextFetchPolicy: "network-only",
      onCompleted: data => {
        const rosters = data.storeOrderRostersBySite?.items || [];
        setRosters(rosters as StoreOrderRoster[]);
      },
    });
  }, [storeId]);

  const [createOrder] = useCreateOrderMutation({
    update(cache, { data }) {
      const newOrder: OrderDetail = (data?.createOrder?.item as OrderDetail) || ({} as OrderDetail);
      cache.modify({
        fields: {
          ordersByStore(existingOrders) {
            const orders = existingOrders.items || [];
            return {
              ...existingOrders,
              items: [{ __ref: `Order:${newOrder.id}` }, ...orders],
            };
          },
        },
      });
    },
  });

  const filteredRosters = rosters?.filter(roster => roster?.isActive);
  const currentOrderRoster = rosters?.find(roster => roster.id === currentOrder.rosterId);
  const isCurrentOrderRosterExpired = !currentOrderRoster;

  return (
    <GenericPage
      title="Order Roster"
      maxWidth="xl"
      actions={
        <IconButton color="inherit" disabled={!currentOrder || !currentOrder.id} to={`/order/edit/${currentOrder && currentOrder.id}/`}>
          <Badge color="warning" variant="dot" invisible={isEmpty}>
            <ShoppingCart />
          </Badge>
        </IconButton>
      }
    >
      {isCurrentOrderRosterExpired && currentOrder?.id && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You have an order in progress, that has an expired roster.&nbsp;
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/order/edit/${currentOrder.id}/`);
            }}
          >
            click here
          </Link>
          &nbsp;to select new roster
        </Alert>
      )}
      {showRosterSelectionError ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          Selected roster is expired. please refresh your browser
        </Alert>
      ) : (
        <></>
      )}
      <DataGrid
        hideFooter={true}
        onRowSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        checkboxSelection={false}
        rowSelectionModel={selectionModel}
        loading={rostersLoading}
        disableColumnFilter
        slotProps={{ toolbar: { showQuickFilter: false } }}
        columns={[
          {
            field: "deliveryDateTimeUtc",
            headerName: "Delivery Date",
            width: 200,
            type: "string",
            valueGetter: params => {
              return moment(params.row.deliveryDateTimeUtc).format(dateFormat);
            },
          },
          {
            field: "actions",
            type: "actions",
            align: "left",
            minWidth: 200,
            getActions: params => {
              const { isActive } = params.row;
              const currentOrderRosterId = currentOrder.rosterId;
              const isCurrent = params.row.id === currentOrder.rosterId;

              if (isActive && !isCurrent)
                return [
                  <Button
                    sx={{ minWidth: "6rem" }}
                    variant="contained"
                    color="primary"
                    key="1"
                    disabled={!!currentOrderRosterId}
                    onClick={() => {
                      const isRosterValid = moment().isBefore(params.row.cutOffDateTimeUtc);
                      const selectedRosterId = params.row.id;
                      const roster = rosters.find(roster => roster.id === selectedRosterId);
                      if (!isRosterValid) {
                        setshowRosterSelectionError(true);
                        return;
                      }
                      createOrder({
                        variables: {
                          orderInput: {
                            storeId: storeId || "",
                            createdBy: auth.user?.profile.sub || "",
                            rosterId: selectedRosterId,
                            sourceOfSupplyName: roster?.sourceOfSupplyName || "",
                            sourceOfSupplyId: roster?.sourceOfSupplyNumber || "",
                            deliveryDateTimeUtc: roster?.deliveryDateTimeUtc || "",
                            rosterCutOffDateTimeUtc: roster?.cutOffDateTimeUtc || "",
                          },
                        },
                        awaitRefetchQueries: true,
                        refetchQueries: [
                          {
                            query: GetCurrentOrderByStoreDocument,
                            variables: { storeId },
                          },
                        ],
                        onCompleted: data => {
                          const orderId = data?.createOrder?.item?.id;
                          navigate(`/order/edit/${orderId}/`);
                        },
                      });
                    }}
                  >
                    Order
                  </Button>,
                ];

              if (isActive && isCurrent)
                return [
                  <Button
                    sx={{ minWidth: "6rem" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(`/order/edit/${currentOrder.id}/`);
                    }}
                    key="2"
                  >
                    Resume
                  </Button>,
                ];

              return [
                <Button sx={{ minWidth: "6rem" }} disabled variant="contained" color="inherit" key="3">
                  closed
                </Button>,
              ];
            },
          },
          {
            field: "sourceOfSupply",
            headerName: "Supplier Code",
            flex: 1,
            valueGetter: params => {
              return `${params.row.sourceOfSupplyName}(${params.row.sourceOfSupplyNumber})`;
            },
          },
          {
            field: "cutOffDateTimeUtc",
            headerName: "Cut Off Time",
            width: 200,
            type: "string",
            valueGetter: params => {
              return `${moment(params.row.cutOffDateTimeUtc).format(dateTimeFormat)}`;
            },
          },
        ]}
        isRowSelectable={(params: GridRowParams) => params.row.isActive}
        rows={filteredRosters}
      />
    </GenericPage>
  );
};

export default RosterPage;
