import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import moment from "moment";
import { CalculatedDates } from "src/graphql/types";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

interface Props {
  calcDates: CalculatedDates | undefined;
  updateSuccess: boolean;
  updating: boolean;
  HandleClick: any;
}

const CustomToolbar = ({ calcDates, updateSuccess, updating, HandleClick }: Props) => {
  const theme = useTheme();

  return (
    <GridToolbarContainer style={{ justifyContent: "space-between" }}>
      {calcDates != undefined && (
        <div style={{ marginTop: "10px", marginLeft: "20px" }}>
          <ArrowBackIosIcon
            className="icon-styles"
            style={{
              fontSize: "15px",
              cursor: calcDates.preEnabled ? "pointer" : "not-allowed",
              color: calcDates.preEnabled ? "black" : "#EBEBE4",
            }}
            onClick={() => HandleClick("previous", calcDates.preEnabled)}
          ></ArrowBackIosIcon>
          <b>{`${moment(calcDates?.startDate).format("MMM DD, YYYY")} - ${moment(calcDates?.endDate).format("MMM DD, YYYY")}`}</b>
          <ArrowForwardIosIcon
            className="icon-styles"
            style={{
              fontSize: "15px",
              cursor: calcDates.nextEnabled ? "pointer" : "not-allowed",
              color: calcDates.nextEnabled ? "black" : "#EBEBE4",
            }}
            onClick={() => HandleClick("next", calcDates.nextEnabled)}
          ></ArrowForwardIosIcon>{" "}
          {!calcDates.nextEnabled && <LockOpenIcon className="icon-styles"></LockOpenIcon>}
          {calcDates.nextEnabled && <LockIcon className="icon-styles"></LockIcon>}
          <div className="salesDate">
            <b> Compare to: </b>
            {`${moment(calcDates?.saleStartDate).format("MMM DD, YYYY")} - ${moment(calcDates?.saleEndDate).format("MMM DD, YYYY")}`}
          </div>
        </div>
      )}
      <Box sx={{ px: 2, display: "flex" }}>
        <GridToolbarQuickFilter />
        {updating ? (
          <Box sx={{ color: theme.palette.primary.main }}>
            <b style={{ fontSize: "12px" }}>Saving</b>
            <CircularProgress size={22} sx={{ verticalAlign: "middle", ml: 1 }}></CircularProgress>
          </Box>
        ) : (
          <Box
            sx={{
              color: updateSuccess ? theme.palette.primary.main : theme.palette.error.main,
            }}
          >
            <b style={{ fontSize: "12px" }}>{updateSuccess ? "Auto saved" : "Error Occurred"}</b>
            <SaveIcon sx={{ verticalAlign: "middle", pl: 1, fontSize: "26px" }}></SaveIcon>
          </Box>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
